import React from 'react'
import {Link} from 'gatsby'
import logo from "../../assets/images/visionar_logo_2021.svg"
import appStoreBedge from "../../assets/images/apple-appstore-badge.svg"
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import {StaticImage} from 'gatsby-plugin-image';

const Footer = () => {

    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer-area bg-color">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-sm-6">
                        <div className="single-footer-widget">
                            <a href="/" className="logo">
                                <img style={{width: '200px'}} src={logo} alt="logo" />
                            </a>
                            <p>Download our free app and scan visionar powered content to unlock the magic.</p>
                            <a href='https://itunes.apple.com/at/app/v-view/id894538059?mt=8'><img alt='Available on the App Store' src={appStoreBedge}/></a>
                            <a href='https://play.google.com/store/apps/details?id=com.visionar.sample'>
                                <StaticImage src="../../assets/images/google-play-badge.png" alt="Get it on Google Play" height={40} />
                            </a>
                        </div> 
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="single-footer-widget pl-5">
                            <h3>Explore</h3>
                            
                            <ul className="footer-links-list">
                                <li>
                                    <Link to="/">
                                        Home
                                    </Link>
                                </li>
                                <li>
                                    <AnchorLink to="/#benefits"
                                                title="Why Visionar?" />
                                </li>
                                <li>
                                    <Link to="/pricing">
                                        Pricing
                                    </Link>
                                </li>
                                {/*<li>*/}
                                {/*    <Link to="/about-us">*/}
                                {/*        About*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                {/*    <Link to="/visionar-studio">*/}
                                {/*        Visionar Studio*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                {/*    <Link to="/visionar-mobile-app">*/}
                                {/*        Visionar Mobile App*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                {/*    <Link to="/visionar-mobile-sdk">*/}
                                {/*        Visionar Mobile SDK*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Resources</h3>

                            <ul className="footer-links-list">
                                <li>
                                    <Link to="/faq">
                                        FAQ
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/blog">
                                        Blog
                                    </Link>
                                </li>
                                {/*<li>*/}
                                {/*    <Link to="/contact">*/}
                                {/*        Contact*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                {/*    <Link to="https://visionar.tawk.help/">*/}
                                {/*        Help Center*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                <li>
                                    <Link to="/showcases">
                                        Showcases
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Address</h3>

                            <ul className="footer-contact-info">
                                <li>
                                    <i className='bx bx-building'></i> 
                                    visionar GmbH
                                </li>
                                <li>
                                    <i className='bx bx-map'></i> 
                                    Mitterweg 16/II <br />  6020 Innsbruck <br /> Austria
                                </li>
                                {/*<li>*/}
                                {/*    <i className='bx bx-envelope'></i>*/}
                                {/*    <a href="mailto:support@visionar.com">support@visionar.com</a>*/}
                                {/*</li>*/}
                                <ul className="social-link">
                                    <li style={{marginBottom: '0'}}>
                                    
                                        <a
                                        href="https://www.facebook.com/visionarAugmentedReality/"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="d-block">
                                        <i style={{color: 'white'}} className='bx bxl-facebook'></i>
                                    </a>
                                    </li>
                                    <li style={{paddingLeft: '0', marginBottom: '0'}}>
                                    
                                        <a
                                        href="https://twitter.com/VisionarAR"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="d-block">
                                        <i style={{color: 'white'}} className='bx bxl-twitter'></i>
                                    </a>
                                    </li>
                                    {/* <li style={{paddingLeft: '0', marginBottom: '0'}}>
                                        <Link to="#" className="d-block" target="_blank" rel="noreferrer">
                                            <i className='bx bxl-instagram'></i>
                                        </Link>
                                    </li> */}
                                    <li style={{paddingLeft: '0', marginBottom: '0'}}>

                                    <a
                                        href="https://www.youtube.com/channel/UChvjmm2h7QXO0Mpy-K1Qq8Q"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="d-block">
                                        <i style={{color: 'white'}} className='bx bxl-youtube'></i>
                                    </a>
                                    </li>
                                </ul>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom-area">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <p>Copyright @{currentYear} <strong>visionar GmbH</strong> All rights reserved.</p>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <ul>
                                <li>
                                    <Link to="/imprint">
                                        Imprint
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/privacy-policy">
                                        Privacy Policy
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/terms-of-service">
                                        Terms & Conditions
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-map">
                <StaticImage src="../../assets/images/footer-map.png" alt="footer-map" />
            </div>
        </footer>
    );
}

export default Footer;