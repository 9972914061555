import React from 'react';
import { useRecoilState } from 'recoil'
import { collapsedState } from '../../utils/recoil-atoms'
import { Link } from 'gatsby'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import logo from "../../assets/images/visionar_logo_2021.svg"

const Navbar = () => {
    const [collapsed, setCollapsed] = useRecoilState(collapsedState);

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    }

    React.useEffect(() => {
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0);
    })

    const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
    const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

    return (
        <React.Fragment>
            <div id="navbar" className="navbar-area">
                <div className="tarn-nav">
                    <div className="container-fluid">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <Link
                                to="/"
                                onClick={() => setCollapsed(true)}
                                className="navbar-brand"
                            >
                                <img className="banner-visionar-logo" src={logo} alt="logo" />
                            </Link>

                            <button
                                onClick={toggleNavbar}
                                className={classTwo}
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className="icon-bar top-bar"></span>
                                <span className="icon-bar middle-bar"></span>
                                <span className="icon-bar bottom-bar"></span>
                            </button>

                            <div className={classOne} id="navbarSupportedContent">
                                <ul className="navbar-nav">
                                    {/*<li className="nav-item">*/}
                                    {/*    <Link*/}
                                    {/*        to="#"*/}
                                    {/*        activeClassName="active"*/}
                                    {/*        onClick={e => e.preventDefault()}*/}
                                    {/*        className="nav-link"*/}
                                    {/*    >*/}
                                    {/*        Products <i className='bx bx-chevron-down'></i>*/}
                                    {/*    </Link>*/}
                                    {/*    <ul className="dropdown-menu">*/}
                                    {/*        <li className="nav-item">*/}
                                    {/*            */}
                                    {/*            <Link*/}
                                    {/*                to="/visionar-studio"*/}
                                    {/*                activeClassName="active"*/}
                                    {/*                onClick={() => setCollapsed(true)}*/}
                                    {/*                className="nav-link"*/}
                                    {/*            >*/}
                                    {/*                /!* <h4>Create</h4>*/}
                                    {/*                <img*/}
                                    {/*                    style={{display: 'block', margin: 'auto'}}*/}
                                    {/*                    src={studioIcon}*/}
                                    {/*                    alt="visionar_studio_desktop_icon"*/}
                                    {/*                    height="48px"*/}
                                    {/*                    width="48px" /> *!/*/}
                                    {/*                Visionar Studio*/}
                                    {/*                /!* <p>Lorem impsum blablabla</p> *!/*/}
                                    {/*            </Link>*/}
                                    {/*        </li>*/}
                                    
                                    {/*        <li className="nav-item">*/}
                                    {/*            */}
                                    {/*            <Link*/}
                                    {/*                to="/visionar-mobile-app"*/}
                                    {/*                activeClassName="active"*/}
                                    {/*                onClick={() => setCollapsed(true)}*/}
                                    {/*                className="nav-link"*/}
                                    {/*            >*/}
                                    {/*                /!* <h4>Experience</h4>*/}
                                    {/*                <img*/}
                                    {/*                    style={{display: 'block', margin: 'auto'}}*/}
                                    {/*                    src={appIcon}*/}
                                    {/*                    alt="visionar_studio_desktop_icon"*/}
                                    {/*                    height="48px"*/}
                                    {/*                    width="48px" /> *!/*/}
                                    {/*                Visionar Mobile App*/}
                                    {/*                /!* <p>Lorem impsum blablabla</p> *!/*/}
                                    {/*                </Link>*/}
                                    {/*        </li>*/}
                                    
                                    {/*        /!*<li className="nav-item">*!/*/}
                                    {/*        /!*    *!/*/}
                                    {/*        /!*    <Link*!/*/}
                                    {/*        /!*        to="/visionar-mobile-sdk"*!/*/}
                                    {/*        /!*        activeClassName="active"*!/*/}
                                    {/*        /!*        onClick={() => setCollapsed(true)}*!/*/}
                                    {/*        /!*        className="nav-link"*!/*/}
                                    {/*        /!*    >*!/*/}
                                    {/*        /!*        /!* <h4>Customize</h4>*!/*/}
                                    {/*        /!*        <img*!/*/}
                                    {/*        /!*            style={{display: 'block', margin: 'auto'}}*!/*/}
                                    {/*        /!*            src={customizeIcon}*!/*/}
                                    {/*        /!*            alt="visionar_studio_desktop_icon"*!/*/}
                                    {/*        /!*            height="48px"*!/*/}
                                    {/*        /!*            width="48px" /> *!/*!/*/}
                                    {/*        /!*        Visionar Mobile SDK*!/*/}
                                    {/*        /!*        /!* <p>Lorem impsum blab labla</p> *!/*!/*/}
                                    {/*        /!*        </Link>*!/*/}
                                    {/*        /!*</li>*!/*/}
                                    {/*    </ul>*/}
                                    {/*</li>*/}
                                    <li className="nav-item">
                                        <AnchorLink to="/#benefits" 
                                                    activeClassName="active"
                                                    className="nav-link"
                                                    onAnchorLinkClick={() => setCollapsed(true)} 
                                                    title="Why Visionar?" />
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            to="/pricing"
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            className="nav-link"
                                        >
                                            Pricing
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                    <Link
                                                    to="/showcases"
                                                    activeClassName="active"
                                                    onClick={() => setCollapsed(true)}
                                                    className="nav-link"
                                                >
                                                    Showcases
                                                </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link
                                          to="/blog"
                                          activeClassName="active"
                                          onClick={() => setCollapsed(true)}
                                          className="nav-link"
                                        >
                                            Blog
                                        </Link>
                                    </li>
                                    {/*<li className="nav-item">*/}
                                    {/*    <Link*/}
                                    {/*        to="#"*/}
                                    {/*        activeClassName="active"*/}
                                    {/*        onClick={e => e.preventDefault()}*/}
                                    {/*        className="nav-link"*/}
                                    {/*    >*/}
                                    {/*        Resources <i className='bx bx-chevron-down'></i>*/}
                                    
                                    {/*    </Link>*/}
                                    
                                    {/*    <ul className="dropdown-menu">*/}
                                    {/*        <li className="nav-item">*/}
                                    {/*            <Link*/}
                                    {/*                to="/blog"*/}
                                    {/*                activeClassName="active"*/}
                                    {/*                onClick={() => setCollapsed(true)}*/}
                                    {/*                className="nav-link"*/}
                                    {/*            >*/}
                                    {/*                Blog*/}
                                    {/*            </Link>*/}
                                    {/*        </li>*/}
                                    
                                    {/*        <li className="nav-item">*/}
                                    {/*            <Link*/}
                                    {/*                to="https://visionar.tawk.help/"*/}
                                    {/*                activeClassName="active"*/}
                                    {/*                onClick={() => setCollapsed(true)}*/}
                                    {/*                className="nav-link"*/}
                                    {/*            >*/}
                                    {/*                Help Center*/}
                                    {/*            </Link>*/}
                                    {/*        </li>*/}
                                    {/*    </ul>*/}
                                    {/*</li>*/}
                                </ul>

                                <div className="others-option d-flex align-items-center">

                                    <div className="option-item">
                                        <a 
                                            href={`${process.env.GATSBY_STUDIO_URL}/login`}
                                            onClick={() => setCollapsed(true)}
                                            className="login-link">
                                                Login
                                            
                                        </a>
                                    </div>

                                    <div className="option-item">
                                        <Link className="default-btn" to="/pricing"><i className="flaticon-right"></i> Get Started <span></span></Link>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Navbar;
