import React from 'react'
import { Helmet } from "react-helmet"

const SEO = () => {
    return (
        <div>
            <Helmet>
                <title>Visionar - Augmented Reality For Everyone</title>
                <meta name="description" content="Visionar - Augmented Reality For Everyone" />
                <meta name="og:title" property="og:title" content="Visionar - Augmented Reality For Everyone"></meta>
                <meta name="twitter:card" content="Visionar - Augmented Reality For Everyone"></meta>
                <link rel="canonical" href="https://www.visionar.com/"></link>
                <meta property="og:image" content="https://visionarpublicfiles.s3.eu-central-1.amazonaws.com/visionar_logo_2021.png" />
            </Helmet>
        </div>
    )
}

export default SEO
